<template>
  <div class="container">
    <div class="menu-bar">
      <div class="logo">
        <a href="#">
          <img
            src="https://oss.qzgjwh.com/webapp/2023/青追国际logo-01 白@2x_1697532759927.png"
            alt="青追国际"
          />
        </a>
      </div>
      <ul class="menu-list" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <li class="menu-item">
          <router-link to="/">首页</router-link>
          <ul>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/', '#company')"
                >公司简介</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/', '#mission')"
                >使命与追求</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/', '#advantage')"
                >我们的优势</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/', '#case')"
                >成长案例</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/', '#culture')"
                >企业文化</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/', '#address')"
                >办公地点</a
              >
            </li>
          </ul>
        </li>
        <li class="menu-item">
          <router-link to="/business">业务</router-link>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/business', '#business-hexin')"
                >5大核心课程</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/business', '#business-sw')"
                >父母升维</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/business', '#business-pb')"
                >线下陪伴</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/business', '#business-qc')"
                >孩子圈层</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/business', '#business-qzpb')"
                >线上陪伴</a
              >
            </li>
          </ul>
        </li>

        <li class="menu-item">
          <router-link to="/article">精选</router-link>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/article', '#job-rcln')"
                >精选文章</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/article', '#job-zxns')"
                >更多关注</a
              >
            </li>
          </ul>
        </li>

        <li class="menu-item">
          <router-link to="/team">团队</router-link>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/team', '#team-csr')"
                >创始人</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/team', '#team-zjt')"
                >专家团</a
              >
            </li>

            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/team', '#team-ppt')"
                >导师团</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/team', '#team-dst')"
                >名师团</a
              >
            </li>
            <!-- <li>
              <a href="javascript:void(0)" @click="goAnchor('/team','#team-yxb')">研习班</a>
            </li> -->
          </ul>
        </li>
        <!--        <li class="menu-item">
                  <router-link to="/news">动态</router-link>
                  <ul>
                    <li><a href="javascript:void(0)" @click="goAnchor('/news','#news-zxdt')">最新动态</a></li>
                    <li><a href="javascript:void(0)" @click="goAnchor('/news','#news-qyzx')">企业资讯</a></li>
                  </ul>
                </li>-->
        <li class="menu-item">
          <router-link to="/job">人才</router-link>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/job', '#job-rcln')"
                >人才理念</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="goAnchor('/job', '#job-zxns')"
                >招贤纳士</a
              >
            </li>
          </ul>
        </li>
        <li class="menu-item">
          <router-link to="/we">我们</router-link>
          <ul>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/we', '#we-ygfz')"
                >员工发展</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="goAnchor('/we', '#we-lxwm')"
                >联系我们</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "QingHeader",
  data() {
    return {};
  },
  methods: {
    // 跳转锚点
    async goAnchor(toPath, id) {
      await this.$router.push(toPath);
      await document.querySelector(id).scrollIntoView(true);
      let anchor = this.$el.querySelector(id);
      document.body.scrollTop = anchor.offsetTop;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  height: 70px;
  width: 100%;

  background-color: #5d9cf9;

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  // 菜单栏
  .menu-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    // 公司logo
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 100%;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    // 导航菜单
    .menu-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 100%;

      // 一级菜单
      .menu-item {
        display: flex;
        align-items: center;
        width: 100px;
        height: 100%;
        font-size: 1.2rem;
        font-weight: bold;

        & > a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          width: 100%;
          height: 100%;
        }

        &:hover {
          cursor: pointer;
        }

        &:hover > a {
          font-size: 1.3rem;
        }

        // 二级菜单
        &:hover > ul {
          display: block;
        }

        ul {
          position: absolute;
          z-index: 99;
          display: none;
          top: calc(100% - 3px);
          width: inherit;
          padding: 10px 0;
          border-top: 3px solid #fff;
          color: #fff;
          background-color: rgba(#000, 0.7);
          font-size: 1em;
          font-weight: normal;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          // 过渡动画
          animation: fadeIn;
          animation-duration: 0.4s;

          li {
            padding: 8px 3px;

            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
